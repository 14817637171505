
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import Multiselect from '@vueform/multiselect';
  import * as Yup from 'yup';
  import { AppraiserUser } from '@/store/modules/Members/AppraisersModule';

  export default defineComponent({
    name: 'appraisers-editing',
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
    },
    async setup() {
      const { t, te, locale } = useI18n();
      const { can } = useAbility();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const submitBanksButton = ref<HTMLButtonElement | null>(null);
      const submitPricesButton = ref<HTMLButtonElement | null>(null);
      const multiselect = ref(null);

      const loading = ref(false);
      const createdUser = ref<AppraiserUser | null>(null);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('appraisersUsersCreating'), [
          translate('members'),
        ]);
      });

      const { data } = await store.dispatch(
        Actions.GET_APPRAISER,
        route.params.id
      );

      const appraiser = ref<AppraiserUser>(data.data.appraiser);
      const selectedBanks = ref<Array<number>>(
        data.data.appraiserBanks.map((a) => a.banks.id)
      );
      const appraiserSchema = Yup.object().shape({
        name: Yup.string()
          .required(() => translate('APPRAISER_NAME_IS_REQUIRED_FIELD'))
          .label('Appraiser Name'),
        email: Yup.string()
          .email(() => translate('EMAIL_NOT_VALID'))
          .required(() => translate('EMAIL_IS_REQUIRED_FIELD'))
          .label('Email'),
        phone: Yup.string()
          .required(() => translate('PHONE_IS_REQUIRED_FIELD'))
          .min(8, () => translate('PHONE_MIN_8'))
          .max(11, () => translate('PHONE_MAX_11'))
          .label('Phone'),
        personName: Yup.string()
          .required(() => translate('PERSON_NAME_IS_REQUIRED_FIELD'))
          .label('Contact Person Name'),
        personPhone: Yup.string()
          .required(() => translate('PERSON_PHONE_IS_REQUIRED_FIELD'))
          .min(8, () => translate('PHONE_MIN_8'))
          .max(11, () => translate('PHONE_MAX_11'))
          .label('Contact Person Phone'),
        bankAccountDetails: Yup.string()
          .required(() => translate('BANK_DETAILS_IS_REQUIRED_FIELD'))
          .label('Bank Account Details'),
        password: Yup.string()
          .min(4, () => translate('PASSWORD_MIN_4'))
          .max(20, () => translate('PASSWORD_MAX_20'))
          .matches(/^(?=.*[a-z])/, translate('PASSWORD_AT_LEAST_ONE_LOWER'))
          .matches(/^(?=.*[A-Z])/, translate('PASSWORD_AT_LEAST_ONE_UPPER'))
          .matches(/^(?=.*\d)/, translate('PASSWORD_AT_LEAST_ONE_NUMBER'))
          .matches(
            /^.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?].*$/,
            translate('PASSWORD_AT_LEAST_ONE_SPECIAL')
          )
          .label('Password'),
        verifyPassword: Yup.string()
          .when('password', {
            is: (password) => password?.length > 0,
            then: Yup.string().required(() =>
              translate('VERIFY_PASSWORD_IS_REQUIRED_FIELD')
            ),
          })
          .oneOf([Yup.ref('password'), null], () =>
            translate('PASSWORDS_MUST_MATCH')
          )
          .label('Verify Password'),
      });

      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        delete values.verifyPassword;

        await store.dispatch(Actions.UPDATE_APPRAISER_ACCOUNT, {
          id: appraiser.value?.id,
          data: values,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_APPRAISER'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            //Deactivate indicator
            submitButton.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitButton.value!.disabled = false;
          });
        }
      };

      const onSubmitBanks = async () => {
        if (submitBanksButton.value) {
          // eslint-disable-next-line
          submitBanksButton.value!.disabled = true;
          submitBanksButton.value.setAttribute('data-kt-indicator', 'on');
        }
        const payload = {
          appraiserId: appraiser.value?.id,
          appraisersBanks: selectedBanks.value,
        };
        await store.dispatch(Actions.UPDATE_APPRAISER_BANKS, payload);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitBanksButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitBanksButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_APPRAISER_BANKS'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            //Deactivate indicator
            submitBanksButton.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitBanksButton.value!.disabled = false;
          });
        }
      };

      await store.dispatch(Actions.GET_BANKS, { limit: 50 });
      await store.dispatch(Actions.GET_ALL_PROPERTY_TYPES);

      const appraiserPrices = store.getters.propertyTypesList.map((p) => {
        const { priceAppraisers } = data.data;
        const target = priceAppraisers.find((r) => r.id === p.id);
        const building = target.appraisersPricing.find(
          (t) => t.propertyStatusId === 2
        );
        const land = target.appraisersPricing.find(
          (t) => t.propertyStatusId === 1
        );
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

        return {
          name: p.name,
          buildingPrices: {
            id: building ? building.id : 0,
            propertyTypeId: p.id,
            propertyStatusId: 2,
            price: building ? building.price : 0,
            percentage: building ? building.percentage : 0,
          },
          landPrices: {
            id: land ? land.id : 0,
            propertyTypeId: p.id,
            propertyStatusId: 1,
            price: land ? land.price : 0,
            percentage: land ? land.percentage : 0,
          },
        };
      });

      const onSubmitPrices = async () => {
        const appraisersPricing = appraiserPrices.reduce(
          (previousValue, currentValue) => {
            previousValue.push(
              currentValue.buildingPrices,
              currentValue.landPrices
            );
            return previousValue;
          },
          []
        );

        const payload = {
          appraiserId: appraiser.value?.id,
          appraisersPricing,
        };

        if (submitPricesButton.value) {
          // eslint-disable-next-line
          submitPricesButton.value!.disabled = true;
          submitPricesButton.value.setAttribute('data-kt-indicator', 'on');
        }

        await store.dispatch(Actions.UPDATE_APPRAISER_PRICING, payload);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitPricesButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitPricesButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_ADDED_APPRAISER_PRICES'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            //Deactivate indicator
            submitPricesButton.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitPricesButton.value!.disabled = false;
          });
        }
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        appraiser,
        propertyTypes: computed(() => store.getters.propertyTypesList),
        submitBanksButton,
        submitPricesButton,
        onSubmitBanks,
        onSubmitPrices,
        onSubmitCreate,
        submitButton,
        translate,
        appraiserSchema,
        goBack,
        can,
        selectedBanks,
        multiSelectLabel: computed(() =>
          locale.value === 'ar' ? 'titleAr' : 'titleEn'
        ),
        loading,
        multiselect,
        createdUser,
        banks: computed(() => store.getters.banksList),
        appraiserPrices,
      };
    },
  });
